import React from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Button,
	Grid,
	SvgIcon,
	makeStyles,
	IconButton,
	Tooltip
} from '@material-ui/core';
import {
	Download as DownloadIcon,
	PlusCircle as PlusCircleIcon,
	Upload as UploadIcon
} from 'react-feather';
import { SearchBox } from '../../';
import Filters from './Filters';
import { useTranslation } from 'react-i18next';
import { TRANSLATE } from 'src/submodules/commons-misc/constant';
import layoutService from 'src/services/layoutService';

const useStyles = makeStyles(theme => ({
	root: {},
	action: {
		'& + &': {
			marginLeft: theme.spacing(2)
		}
	},
	actionIcon: {
		marginRight: theme.spacing(1)
	},
	search: {
		flex: 1
	}
}));

function Header({
	sort,
	filters,
	search,
	dataImport,
	dataExport,
	button,
	params,
	customActions,
	...rest
}) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Box p={2}>
			<Grid className={classes.root} container spacing={3} {...rest}>
				<Grid item xs={12} sm={10} md={10} lg={10}>
					<Box display="flex" alignItems="center" flexWrap="wrap">
						<Filters sort={sort} filters={filters} params={params} />

						{search && (
							<div className={classes.search}>
								<SearchBox
									name={search?.name}
									searchParams={search?.searchParams}
									handleChange={res => {
										search.handleChange(res);
									}}
								/>
							</div>
						)}
					</Box>
				</Grid>
				<Grid item xs={12} sm={2} md={2} lg={2}>
					<Box
						display="flex"
						alignItems="center"
						justifyContent="flex-end"
						flexWrap="wrap"
					>
						<>
							{button && button?.can !== false && (
								<Button
									color="secondary"
									variant="contained"
									className={classes.action}
									onClick={() => button.handleClick()}
									fullWidth={layoutService.isMobile()} // Pulsanti a larghezza piena su mobile
								>
									{button.icon !== false && (
										<SvgIcon fontSize="small" className={classes.actionIcon}>
											<PlusCircleIcon />
										</SvgIcon>
									)}
									{button.text || t(TRANSLATE.NEW)}
								</Button>
							)}

							{dataImport && dataImport?.can !== false && (
								<Tooltip title={t(TRANSLATE.IMPORT)}>
									<IconButton
										className={classes.action}
										ref={dataImport?.ref}
										onClick={() => dataImport.onClick()}
									>
										<SvgIcon fontSize="small">
											<UploadIcon />
										</SvgIcon>
									</IconButton>
								</Tooltip>
							)}

							{dataExport && dataExport?.can !== false && (
								<Tooltip title={t(TRANSLATE.EXPORT)}>
									<IconButton
										className={classes.action}
										ref={dataExport?.ref}
										onClick={() => dataExport.onClick()}
									>
										<SvgIcon fontSize="small">
											<DownloadIcon />
										</SvgIcon>
									</IconButton>
								</Tooltip>
							)}
							{customActions && customActions}
						</>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

Header.propTypes = {
	className: PropTypes.string
};

export default Header;
